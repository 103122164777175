import React, {useEffect, useState} from 'react';
import {TablePagination} from '@mui/material';
import {styled} from "@mui/material/styles";

interface PaginatorProps {
    onPageChange: (offset: number, limit: number) => void;
    total: number;
    limit: number;
    offset: number;
}

export function getPageNumber(limit: number, offset: number): number {
    return Math.floor(offset / limit)
}

const TablePaginator: React.FC<PaginatorProps> = ({onPageChange, total, limit = 10, offset = 0}) => {

    const [iOffset, setOffset] = useState<number>(offset);
    const [iLimit, setLimit] = useState<number>(limit);

    useEffect(() => {
        onPageChange(iOffset, iLimit);
    }, [iOffset, iLimit]);

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
        const newOffset = value * limit
        setOffset(newOffset);
    };

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newPageSize = parseInt(event.target.value, 10);
        setLimit(newPageSize);
        setOffset(0); // Reset to the first offset whenever offset size changes
    };


    return <StyledTablePagination
        style={{width: '100%'}}
        page={getPageNumber(iLimit, iOffset)}
        count={total}
        rowsPerPage={iLimit}
        labelRowsPerPage={'Per Page'}
        onPageChange={(event, newPage) => handlePageChange(event, newPage)}
        onRowsPerPageChange={handlePageSizeChange}
        rowsPerPageOptions={[5, 15, 25, 50]}
    />
};

const StyledTablePagination = styled(TablePagination)(({theme}) => ({
    borderBottom: 'none',          // No bottom border
    width: '100%',                 // Full width
    padding: theme.spacing(0.5),   // Dense padding all around
}));


export default TablePaginator;
