import React, {useEffect, useState} from 'react';
import {Box, Grid, LinearProgress, Typography} from "@mui/material";
import {ApiListResponse, Friendships} from "../object-actions/types/types";
import TablePaginator from "../components/TablePaginator";
import {useLocation, useNavigate} from "react-router-dom";
import FriendInvite from "./FriendInvite";
import {PagiFilters, parsePagiFiltersFromURL, useData} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";
import ApiClient from "../config/ApiClient";
import {useAuth} from "../allauth/auth";
import {ButtonPill} from "../forms/StyledFields";
import {useSnackbar} from "notistack";
import {useFocusedContext} from "../contexts/FocusedProvider";

interface FriendSelectorProps {
    event_id: number | string;
}

const FriendSelector: React.FC<FriendSelectorProps> = ({event_id}) => {
    const location = useLocation();
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const [selectedFriends, setselectedFriends] = useState<{[key:string | number]:string}>({});
    const [syncing, setSyncing] = useState<boolean>(false);
    const navigate = useNavigate();
    const me = useAuth()?.data?.user
    const {enqueueSnackbar} = useSnackbar()
    const {fetchGuestList} = useFocusedContext()

    let apiUrl = `/api/friendships`
    const params = new URLSearchParams(location.search);
    params.set('status', 'accepted,pending')
    if (event_id) {
        params.set('event_id', event_id.toString())
    }
    apiUrl += `?${params.toString()}`

    const {
        apiResponse,
        error,
        isLoading
    } = useData<ApiListResponse<Friendships>>(apiUrl, pagination, (): any => fetchList(apiUrl, pagination));

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: limit,
            offset: offset
        }));
    }

    const handleSelect = (id: number | string) => {
        setselectedFriends(prev => {
            const newSelected = {...prev};
            if (typeof newSelected[id] !== 'undefined') {
                delete newSelected[id];
            } else {
                newSelected[id] = 'invited';
            }
            return newSelected;
        });
    };

    function getOtherId(obj:Friendships) {
        return obj['recipient'] && obj['author'].id === me.id ? obj['recipient'].id : obj['author'].id

    }

    const handleFriendsInvites = async () => {
        setSyncing(true)
        const friends = Object.keys(selectedFriends)
        for (let i = 0; i < friends.length; i++) {
            const response = await ApiClient.post(`/api/invites`, {
                status: 'invited',
                event: event_id,
                author: me.id,
                recipient: friends[i]
            });
        }
        enqueueSnackbar(`Invites sent`)
        fetchGuestList(event_id)
        setSyncing(false)
        navigate('/')
    }

    if (isLoading && !apiResponse?.results) return <Box p={2}><LinearProgress/></Box>

    if (error) return <Box p={2}><Typography variant={'body2'} color={"error"}>{error}</Typography> </Box>

    return (
        <React.Fragment>
            {isLoading && <LinearProgress/>}

            {apiResponse.count > apiResponse.results.length && (
                <TablePaginator
                    total={apiResponse.count} limit={apiResponse.limit} offset={apiResponse.offset}
                    onPageChange={handlePagination}
                />
            )}
            <Grid container>
                {apiResponse.results.map((obj, i) => {
                        return <Grid xs={12} item key={`entitycard-${i}`}>
                            <FriendInvite onClick={handleSelect}
                                          selected={typeof selectedFriends[getOtherId(obj)] !== 'undefined'}
                                          entity={obj as Friendships}
                                          invite={obj?.invite}
                                          event_id={event_id} />
                        </Grid>
                    }
                )}
                <Grid item mt={2} xs={12} style={{textAlign:'center'}}>
                <ButtonPill variant={'contained'}
                            disabled={syncing}
                            onClick={() => handleFriendsInvites()}>Send</ButtonPill>
                </Grid>
            </Grid>

        </React.Fragment>
    );
};

export default FriendSelector;
