import React, {useEffect} from 'react';
import {Box, Typography} from "@mui/material";
import {Events} from "../object-actions/types/types";
import {isCurrent, makeAbsolute} from "../utils";
import {Link, useNavigate} from "react-router-dom";
import ApiClient from "../config/ApiClient";
import {People} from "@mui/icons-material";
import {BackgroundImage} from "./FullscreenBg";
import {FadedPaper} from "../forms/StyledFields";

interface MyEventCardProps {
    entity: Events;
}

const MyEventCard: React.FC<MyEventCardProps> = ({entity}) => {
    const navigate = useNavigate();
    // WARN: DO NOT USE FOCUSED CONTEXT FOR CARDS. MULTIPLES APPEAR PER PAGE
    const [inviteCounts, updateData] = React.useState<{ [key: string]: number }>({});

    useEffect(() => {
        async function fetchData() {
            let apiUrl = `/api/invites/counts-by-event/${entity.id}`
            apiUrl += `?${window.location.search}`
            const response = await ApiClient.get(apiUrl);
            if (response.error) {
                alert(response.error)
            }

            // @ts-ignore
            updateData(response.data)
        }

        fetchData()
    }, []);

    const isActive = isCurrent(entity.starts, entity.ends)

    const styles: any = {
        width: '100%',
        borderRadius: 16,
        padding: '12px 4px',
        textAlign: 'center',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textDecoration: 'none'
    };

    return <FadedPaper style={styles}>
        {/* @ts-ignore */}
        <BackgroundImage imageUrl={entity.cover ? makeAbsolute(entity.cover) : '/events-background.png'}
                         position={'absolute'} style={{borderRadius: 16}}
                         opacity={1} blur={8}/>
        <Box component={Link}
             style={{textDecoration: 'none', position: 'relative', textAlign: 'center', padding: '5%'}}
             onClick={() => navigate(`/events/${entity.id}`, {state: {inviteCounts: inviteCounts}})}

        >
             <Box style={{position: 'relative'}} pl={10} pr={10}>
            <div
                style={{
                    width: '100%',
                    borderRadius: 16,
                    paddingTop: '100%', // This creates a square container
                    backgroundImage: `url(${makeAbsolute(entity.cover)})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    margin: 'auto auto 20px auto',
                }}
                aria-label="event flyer"
            />
             </Box>
            <Typography variant={'h3'}>{entity.name} {isActive &&
              <span style={{color: 'lime'}}>•</span>}</Typography>
            {inviteCounts &&
              <React.Fragment>
                <People/>
                <Typography variant={'body2'}>Going</Typography>
                <Typography variant={'h3'}>{inviteCounts.accepted}</Typography>
              </React.Fragment>
            }
        </Box>
    </FadedPaper>
}

export default MyEventCard;
