import React, {ChangeEvent, useState} from 'react';
import {Typography} from "@mui/material";
import {Invites} from "../object-actions/types/types";
import ApiClient from "../config/ApiClient";
import {FriendButton} from "../forms/StyledFields";
import Avatar from "@mui/material/Avatar";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {useNavigate} from "react-router-dom";
import {getFullName, makeAbsolute} from "../utils";
import {parsePagiFiltersFromURL, useDataContext} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";

interface InviteProps {
    entity: Invites;
}

const InviteSentByMe: React.FC<InviteProps> = ({entity}) => {
    const [status, setStatus] = useState<string>(entity.status);
    const navigate = useNavigate();
    const {fetchData} = useDataContext();

    const handleStatusChange = async (e: ChangeEvent<HTMLInputElement> | any) => {
        const {value} = e.target;

        if (value === 'withdrawn') {
            if (!window.confirm('Are you sure you want to withdraw this invitation?')) {
                return true;
            }
        }

        const response = await ApiClient.patch(`/api/invites/${entity.id}`, {status: value});
        if (response.success && response.data) {
            console.log(response.data);
            setStatus(value)

            const apiUrl = (entity.event?.id && window.location.pathname.indexOf('/events/') === 0) ? `/api/invites/by-event/${entity.event?.id}?status=accepted,invited` : `/api/invites?status=accepted,invited`
            const pagination = parsePagiFiltersFromURL()
            fetchData(apiUrl, () => fetchList(apiUrl, pagination), true)
        }
        if (response.error) {
            alert(response.error)
        }
    };

    const headerProps: Partial<CardHeaderProps> = {};

    const other = entity['recipient']

    headerProps.avatar =
        <Avatar src={typeof other.img === 'string' ? makeAbsolute(other.img) : other.str}
                alt={other['str']}
                onClick={() => navigate(`/users/${other.id}`)}/>

    if (status === 'accepted') {
        headerProps.title = <Typography
            variant={'subtitle1'}><em>{getFullName(other)}</em> accepted your invite</Typography>
    } else {
        headerProps.title =
            <Typography variant={'subtitle1'}><em>{getFullName(other)}</em> has your friend request</Typography>
    }

    if (entity.status === 'withdrawn') {
        headerProps.action = <FriendButton
            size={'small'}
            variant={'contained'}
            color={'inherit'}
            value={'invited'}
            onClick={handleStatusChange}
        >
            Resend Invitation
        </FriendButton>
    } else {
        headerProps.action = <FriendButton
            size={'small'}
            variant={'contained'}
            color={'inherit'}
            value={'invited'}
            onClick={handleStatusChange}
        >
            Withdraw
        </FriendButton>
    }


    return <CardHeader {...headerProps} />
}

export default InviteSentByMe;
