import React, {useEffect} from 'react';
import {Box, LinearProgress, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import EventsList from "./EventsList";
import {useEventsContext} from "../contexts/EventsProvider";

const EventsPending: React.FC = () => {
    const {invite_status} = useParams();
    const {setPendingEvents, getFilteredEvents, pendingEvents} = useEventsContext()

    useEffect(() => {
        getFilteredEvents('invited', '');
    }, []);

    if (pendingEvents.count === -1) return <Box sx={{padding: 2}} mt={1}><LinearProgress/></Box>
    if (typeof pendingEvents.error === 'string') return <Box sx={{padding: 2}} mt={1}>{pendingEvents.error}</Box>

    return (<Box style={{minHeight: '100vh'}} mt={7}>
        <Typography mt={3} variant={'h3'} ml={2}>Pending Invitations</Typography>
        {(pendingEvents.count === 0) ?
        <Typography mt={3} variant={'subtitle2'} ml={2}>No event invites</Typography>
        :
        <EventsList invite_status={'invited'} listData={pendingEvents}/>
        }
    </Box>)

};

export default EventsPending;
