import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Grid, TextField, Typography} from '@mui/material';
import {ButtonPill} from "../forms/StyledFields";
import {useAuth} from "../allauth/auth";
import ApiClient from "../config/ApiClient";
import ImageUpload, {Upload} from "../object-actions/forms/ImageUpload";
import {useNavigate} from "react-router-dom";
import {getAuth} from "../allauth/lib/allauth";

interface ProfileData {
    first_name: string;
    last_name: string;
    username: string;
    profile_picture: string | Blob;
}

const OnboardProfile: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const me = useAuth()?.data?.user
    const display = (me?.display ?? '').split(' ')
    const [profileData, setProfileData] = useState<ProfileData>({
        first_name: display[0] ?? '',
        last_name: display[1] ?? '',
        username: me?.username ?? '',
        profile_picture: me?.profile_picture ?? ''
    });
    const [step, setStep] = useState({
        fullname: profileData.first_name.length > 0 || profileData.last_name.length > 0,
        username: profileData.username.length > 0 && profileData.username.indexOf('user') !== 0,
        picture: profileData.profile_picture !== ''
    });

    useEffect(() => {
        if (step.fullname && step.picture && step.username) {
            getAuth()
                .then(data => {
                    const event = new CustomEvent('allauth.auth.change', {detail: data})
                    document.dispatchEvent(event)
                    navigate(`/`)
                });

        }
    }, [step]); // The effect runs whenever `state` changes

    const handleNext = async () => {
        setLoading(true);
        try {

            let formData: any = {};
            const headers: any = {
                'accept': 'application/json',
                "Content-Type": "application/json"
            }
            let attempt = 'fullname'
            if (step.fullname === false) {
                formData.first_name = profileData.first_name
                formData.last_name = profileData.last_name
            } else if (step.username === false) {
                formData.username = profileData.username
                attempt = 'username'
            } else if (step.picture === false) {
                formData = new FormData()
                formData.append("profile_picture", profileData.profile_picture)
                headers["Content-Type"] = `multipart/form-data`
                attempt = 'picture'
            }

            const response = await ApiClient.patch(`/api/users/${me.id}`, formData, headers);
            setLoading(false);
            if (response.success) {

                setStep({
                    ...step,
                    [attempt]: true
                });
            } else {
                setError('Failed to save data, please try again.' + response.error);
            }
        } catch (err) {
            setLoading(false);
            setError('Failed to save data, please try again.');
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfileData({
            ...profileData,
            [e.target.name]: e.target.value
        });
    };

    const handleImage = (selected: Upload, field_name: string, index: number) => {
        setProfileData({
            ...profileData,
            "profile_picture": selected?.file ?? ""
        });
    };

    return (
        <Grid container direction={'column'} justifyContent={'space-between'} mt={0}
              p={2} spacing={0} style={{minHeight: '100vh'}}>
            <Grid item></Grid>
            <Grid item>
                {step.fullname === false ?
                    <Box>
                        <Typography variant="h5" mb={2}>Enter Your Real Name</Typography>
                        <TextField
                            fullWidth
                            label="First Name"
                            name="first_name"
                            value={profileData.first_name}
                            onChange={handleChange}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Last Name"
                            name="last_name"
                            value={profileData.last_name}
                            onChange={handleChange}
                            margin="normal"
                        />
                        {error && <Typography color="error">{error}</Typography>}

                    </Box>
                    : step.username === false ?
                        <Box>
                            <Typography variant="h5" mb={2}>Enter Your Username</Typography>
                            <TextField
                                fullWidth
                                label="Username"
                                name="username"
                                value={profileData.username}
                                onChange={handleChange}
                                margin="normal"
                            />
                            {error && <Typography color="error">{error}</Typography>}

                        </Box>
                        :
                        <Box>
                            <Typography variant="h5" mb={2}>Upload Your Profile Picture</Typography>
                            <ImageUpload onSelect={handleImage} index={0}
                                         field_name={"profile_picture"}
                                         selected={""}
                            />
                            {error && <Typography color="error">{error}</Typography>}
                        </Box>
                }
            </Grid>
            <Grid item>

                <ButtonPill
                    style={{alignSelf: 'flex-end', justifySelf: 'flex-end'}}
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    onClick={handleNext}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24}/> : 'Next'}
                </ButtonPill>
            </Grid>
        </Grid>
    );
};

export default OnboardProfile;
