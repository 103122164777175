import React, {useState} from 'react';
import {Box, Grid, LinearProgress, Typography} from "@mui/material";
import {ApiListResponse, Friendships, Invites} from "../object-actions/types/types";
import TablePaginator from "../components/TablePaginator";
import {useLocation} from "react-router-dom";
import Friendship from "../components/Friendship";
import {PagiFilters, parsePagiFiltersFromURL, useData} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";

interface FriendsProps {
    author?: number | string;
}

const Friends: React.FC<FriendsProps> = ({author}) => {
    const location = useLocation();
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());

    let apiUrl = "";
    if (author) {
        apiUrl += `/api/users/${author}/friendships`
    } else {
        apiUrl += `/api/friendships`
    }
    const params = new URLSearchParams(location.search);
    params.set('status', 'accepted')
    apiUrl += `?${params.toString()}`

    const {
        apiResponse,
        error,
        isLoading
    } = useData<ApiListResponse<Friendships>>(apiUrl, pagination, (): any => fetchList(apiUrl, pagination));

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: limit,
            offset: offset
        }));
    }

    if (isLoading && !apiResponse?.results) return <Box p={2}><LinearProgress/></Box>

    if (error) return <Box p={2}><Typography variant={'body2'} color={"error"}>{error}</Typography> </Box>

    return (
        <React.Fragment>
            {isLoading && <LinearProgress/>}

            {apiResponse.count > apiResponse.results.length && (
                <TablePaginator
                    total={apiResponse.count} limit={apiResponse.limit} offset={apiResponse.offset}
                    onPageChange={handlePagination}
                />
            )}
            <Grid container>
                {apiResponse.results.map((obj, i) => <Grid xs={12} item key={`entitycard-${i}`}>
                            <Friendship entity={obj as Friendships}/>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
};

export default Friends;
