import React from 'react';
import {Typography} from "@mui/material";
import {Invites} from "../object-actions/types/types";
import {FriendButton} from "../forms/StyledFields";
import Avatar from "@mui/material/Avatar";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {useNavigate} from "react-router-dom";
import {getFullName, getUsername, makeAbsolute} from "../utils";
import {FriendshipRelButton} from "../forms/FriendshipButton";

interface InviteProps {
    entity: Invites;
}

const InviteSent: React.FC<InviteProps> = ({entity}) => {
    const navigate = useNavigate();

    const headerProps: Partial<CardHeaderProps> = {};

    const recipient = entity['recipient']

    headerProps.avatar =
        <Avatar src={typeof recipient.img === 'string' ? makeAbsolute(recipient.img) : recipient.str}
                alt={recipient['str']}
                onClick={() => navigate(`/users/${recipient.id}`)}/>

    headerProps.title = getFullName(recipient)
    headerProps.subheader = getUsername(recipient)

    headerProps.title = <Typography variant={'subtitle1'}>{getFullName(recipient)}</Typography>
    headerProps.subheader = <Typography variant={'body2'}>invited by <em>{getFullName(entity['author'])}</em></Typography>

    if (entity.friendship) {
        headerProps.action = <FriendshipRelButton friendship={entity.friendship} recipient={recipient} />
    } else {
        headerProps.action = <FriendshipRelButton recipient={recipient} />
    }


    return <CardHeader {...headerProps} />
}

export default InviteSent;
