import React, {ReactElement, useState} from "react";
import {Box, LinearProgress, Typography} from "@mui/material";
import {ApiListResponse, Invites} from "../object-actions/types/types";
import Invite from "../components/Invite";
import {useParams} from "react-router-dom";
import TablePaginator from "../components/TablePaginator";
import {PagiFilters, parsePagiFiltersFromURL, useData} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";
import {useAuth} from "../allauth/auth";
import {SoloCenteredBox} from "../components/FullscreenBg";

interface InviteListProps {
    event_id?: number | string | undefined;
}

type GroupedInvites = {
    [status: string]: ReactElement[];
};

const StatusLabels: {[key:string]:string} = {
    "invited":"Hasn't responded yet",
    "accepted": "Accepted",
    "declined": "Rejected",
    "withdrawn": "Withdrawn"
}

const InviteList: React.FC<InviteListProps> = ({
                                                   event_id
                                               }) => {
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const me = useAuth()?.data?.user
    const params = useParams();
    const final_id = event_id ? parseInt(event_id.toString()) : (params.id ? parseInt(params.id) : 0);

    // TODO if host add invited
    const apiUrl = (final_id > 0 && window.location.pathname.indexOf('/events/') === 0) ? `/api/invites/by-event/${final_id}?status=accepted,invited` : `/api/invites?status=accepted,invited`

    const {
        apiResponse,
        error,
        isLoading
    } = useData<ApiListResponse<Invites>>(apiUrl, pagination, (): any => fetchList(apiUrl, pagination));

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: limit,
            offset: offset
        }));
    }

    if (isLoading && !apiResponse?.results) return <Box p={2}><LinearProgress/></Box>

    if (error) return <SoloCenteredBox><Typography variant={'body2'} color={"error"}>{error}</Typography> </SoloCenteredBox>

   //  const myInvite = apiResponse.results.find(obj => obj.recipient.id === me.id)

    const groups = apiResponse.results.reduce((acc:GroupedInvites, cur:Invites) => {
//        if (myInvite && myInvite.id == cur.id) return acc;
        if (typeof acc[cur.status] === 'undefined') acc[cur.status] = [];
        acc[cur.status].push(<Invite key={`entitycard-${cur.id}`} entity={cur as Invites}/>);
        return acc;
    }, {} as GroupedInvites);


    return (
        <Box mt={8} style={{minHeight: '100vh'}}>

            <Typography variant={'h1'} style={{textAlign:'center'}}  >Invite List</Typography>

            {isLoading && <LinearProgress />}

            {apiResponse.count > apiResponse.results.length && (
                <TablePaginator
                    total={apiResponse.count} limit={apiResponse.limit} offset={apiResponse.offset}
                    onPageChange={handlePagination}
                />
            )}

            <Box p={1}>
            {Object.entries(groups).map(([key, value]) => <Box mb={1} key={`invitelist-${key}`}>
                    {typeof StatusLabels[key] === 'string' && <Typography variant={'h3'} >{StatusLabels[key]}</Typography>}
                    {value}
                </Box>
            )}
            </Box>
        </Box>
    );
};

export default InviteList;
