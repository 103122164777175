import React, {useEffect, useState} from "react";
import {Box, Grid, LinearProgress, Typography, useTheme} from "@mui/material";
import {Events} from "../object-actions/types/types";
import {formatDateRange, getUsername, isCurrent, makeAbsolute} from "../utils";
import AvatarsGrouped from "../components/AvatarsGrouped";
import {useAuth} from "../allauth/auth";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Paper from "@mui/material/Paper";
import {ButtonPill} from "../forms/StyledFields";
import PlaylistCta from "../components/PlaylistCta";
import {alpha} from "@mui/system";
import CheckinButton from "../forms/CheckinButton";
import {useFocusedContext} from "../contexts/FocusedProvider";
import {Circle} from "@mui/icons-material";
import InvitesSummary from "../components/InvitesSummary";
import InviteRsvp from "../components/InviteRsvp";
import SongRequestList from "../components/activities/SongRequestList";

interface EventDetailsProps {
    id: number;
}

const EventDetails: React.FC<EventDetailsProps> = ({id}) => {

    const {focusedEvent, fetchEvent} = useFocusedContext()

    useEffect(() => {
        fetchEvent(id)
    }, [id, focusedEvent]);

    const location = useLocation();
    const [view, setView] = useState('full');

    useEffect(() => {
        setView('short');
    }, [location]);

    if (!focusedEvent) return <Box p={2}><LinearProgress/></Box>;

    if (view === 'short') {
        return <EventShortView entity={focusedEvent} viewMode={'short'}/>
    }
    return <EventFullView entity={focusedEvent} viewMode={view}/>

};


interface EventViewProps {
    entity: Events;
    viewMode: string;
}

export const EventShortView: React.FC<EventViewProps> = ({entity, viewMode = 'short'}) => {
    const navigate = useNavigate()

    const isActive = isCurrent(entity.starts, entity.ends);

    const myInvite = entity.invite
    const dest = entity.checkin && entity.checkin.str === 'entered' ? `/events/${entity.id}/checked-in` : `/events/${entity.id}`

    return (
        <React.Fragment>
            <img
                onClick={() => {
                    navigate(dest);
                }}
                src={entity.cover}
                style={{
                    width: "100%",
                    height: 200,
                    borderRadius: 16,
                    textAlign: "center",
                    objectFit: "cover",
                    cursor: "pointer",
                }}
            />

            <Grid
                component={Paper}
                style={{borderRadius: 16, marginTop: -10}}
                container
                p={2}
                justifyContent={"space-between"}
                alignContent={"center"}
                alignItems={"center"}
            >
                <Grid item xs={8}>
                    <Typography variant={"h3"}>
                        {entity.name} {isActive && <span style={{color: "lime"}}>•</span>}
                    </Typography>
                    <Typography
                        variant={"body1"}
                        style={{color: "#AAAAAA", textDecoration: "none"}}
                        component={Link}
                        to={`/users/${entity.author.id}`}
                        gutterBottom={true}
                    >
                        {getUsername(entity.author)}
                    </Typography>
                    <Typography variant={"body2"} gutterBottom={true}>
                        {entity.description}
                    </Typography>
                    <Typography
                        component={"a"}
                        variant={"body2"}
                        gutterBottom={true}
                        style={{color: "#868484"}}
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                            entity?.coordinates || entity.address
                        )}`}
                        target="_blank"
                    >
                        {entity.address}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography gutterBottom={true} variant={"body2"}>
                        {formatDateRange(entity.starts, entity.ends)}
                    </Typography>
                    <AvatarsGrouped
                        event_id={entity.id}
                        entities={
                            Array.isArray(entity.cohosts) && entity.cohosts.length > 0
                                ? [entity.author].concat(entity.cohosts)
                                : [entity.author]
                        }
                    />
                </Grid>
                {myInvite && myInvite.status === 'accepted' && isActive ? (
                    <Grid item xs={12} style={{textAlign: 'center'}} mt={2}>
                        <CheckinButton event={entity} checkin={entity.checkin ?? undefined}/>
                    </Grid>
                ) : myInvite && myInvite.status === 'invited' ?
                    <Grid item xs={12} mt={2}>
                        {myInvite && <InviteRsvp entity={myInvite}/>}
                    </Grid>
                    : null}
            </Grid>
        </React.Fragment>
    );
}


export const EventFullView: React.FC<EventViewProps> = ({entity, viewMode = 'full'}) => {
    const navigate = useNavigate();
    const theme = useTheme()
    const me = useAuth()?.data?.user
    const isActive = viewMode === 'active' || isCurrent(entity.starts, entity.ends);
    const canManage = entity.author.id === me.id ?? (entity.cohosts && entity?.cohosts.findIndex(rel => rel.id === me.id) > -1);
    const myInvite = entity.invite

    return (
        <Box>

            <PlaylistCta entity={entity}/>

            <Box
                style={{
                    position: `relative`,
                    width: '100%',
                    height: '100%',
                    minHeight: '45vh',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `
        linear-gradient(180deg, 
            ${alpha(theme.palette.background.default, 0)} 0%,   
            ${alpha(theme.palette.background.default, 0)} 60%,   
            ${alpha(theme.palette.background.default, 1)} 75%,   
            ${alpha(theme.palette.background.default, 1)} 100%),
        url(${makeAbsolute(entity.cover)})
    `,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                }}>

                <Grid container flexDirection={'column'} justifyContent={'flex-end'} p={2} style={{minHeight: '45vh'}}
                >
                    <Grid item>
                        <Typography variant={"h2"}>{entity.name}</Typography>

                        <Typography
                            variant={"h3"}
                            style={{color: "#AAAAAA", textDecoration: "none"}}
                            component={Link}
                            to={`/users/${entity.author.id}`}
                            gutterBottom={true}
                        >
                            {getUsername(entity.author)}
                        </Typography>
                    </Grid>

                    {isActive &&
                      <Typography variant={"body1"}>
                        Active <Circle color={'success'} sx={{fontSize: 11, verticalAlign: 'middle'}}/>
                      </Typography>
                    }

                    <Box mt={.5} ml={1} mb={2}>
                        <InvitesSummary entity={entity} format={'justavatars'} canManage={canManage}/>
                    </Box>

                    <Box>
                        <Typography gutterBottom={true} variant={"body2"}>
                            <span>⏰ </span> {formatDateRange(entity.starts, entity.ends)}
                        </Typography>
                    </Box>
                    <Box mb={1}>
                        <span>📍</span>
                        <Typography
                            component={"a"}
                            variant={"body2"}
                            gutterBottom={true}
                            style={{color: "#868484"}}
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                entity.coordinates || entity.address
                            )}`}
                            target="_blank"
                        >
                            {entity.address}
                        </Typography>
                    </Box>

                    <Typography variant={"body2"} gutterBottom={true}>
                        {entity.description}
                    </Typography>
                </Grid>
            </Box>

            <Grid item p={2}>
                <img
                    src={`https://maps.googleapis.com/maps/api/staticmap?key=${process.env.REACT_APP_STATIC_MAP_KEY}&center=${entity.coordinates || entity.address}&zoom=12&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0x242f3e&style=element:labels.text.fill%7Ccolor:0x746855&style=element:labels.text.stroke%7Ccolor:0x242f3e&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xd59563&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0xd59563&style=feature:poi.park%7Celement:geometry%7Ccolor:0x263c3f&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x6b9a76&style=feature:road%7Celement:geometry%7Ccolor:0x38414e&style=feature:road%7Celement:geometry.stroke%7Ccolor:0x212a37&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x9ca5b3&style=feature:road.highway%7Celement:geometry%7Ccolor:0x746855&style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0x1f2835&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0xf3d19c&style=feature:transit%7Celement:geometry%7Ccolor:0x2f3948&style=feature:transit.station%7Celement:labels.text.fill%7Ccolor:0xd59563&style=feature:water%7Celement:geometry%7Ccolor:0x17263c&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x515c6d&style=feature:water%7Celement:labels.text.stroke%7Ccolor:0x17263c&size=480x360`}
                    alt="event map"
                    className="w-full rounded-lg"
                />
            </Grid>

            {canManage &&
              <Grid item p={2} style={{textAlign: 'center'}}>
                <ButtonPill
                  onClick={() => navigate(`/events/${entity.id}/invite`)}
                  variant={"contained"}
                  color={"primary"}
                >
                  Invite a Friend
                </ButtonPill>
              </Grid>
            }

            {myInvite && myInvite.status !== 'accepted' && <Box p={2}><InviteRsvp entity={myInvite}/></Box>}
            {myInvite && myInvite.status === 'accepted' && isActive && <Grid item p={2} style={{textAlign: 'center'}}>
              <ButtonPill
                onClick={() => navigate(`/events/${entity.id}/request-song`)}
                variant={"contained"}
                color={"primary"}
              >
                Request a Song
              </ButtonPill>
            </Grid>}

        </Box>
    );
}


export const EventCheckedIn: React.FC<EventViewProps> = ({entity, viewMode = 'full'}) => {
    const navigate = useNavigate();
    const theme = useTheme()
    const me = useAuth()?.data?.user
    const isActive = viewMode === 'active' || isCurrent(entity.starts, entity.ends);
    const canManage = entity.author.id === me.id ?? (entity.cohosts && entity?.cohosts.findIndex(rel => rel.id === me.id) > -1);

    return (
        <Box>

            <PlaylistCta entity={entity}/>

            <Box
                style={{
                    position: `relative`,
                    width: '100%',
                    height: '100%',
                    minHeight: '45vh',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `
        linear-gradient(180deg, 
            ${alpha(theme.palette.background.default, 0)} 0%,   
            ${alpha(theme.palette.background.default, 0)} 60%,   
            ${alpha(theme.palette.background.default, 1)} 75%,   
            ${alpha(theme.palette.background.default, 1)} 100%),
        url(${makeAbsolute(entity.cover)})
    `,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                }}>

                <Grid container flexDirection={'column'} justifyContent={'flex-end'} p={2} style={{minHeight: '45vh'}}
                >
                    <Grid item>
                        <Typography variant={"h2"}>{entity.name}</Typography>


                        <Typography variant={"body2"} gutterBottom={true}>
                            {entity.description}
                        </Typography>
                    </Grid>

                    {isActive &&
                      <Typography variant={"body1"}>
                        Active <Circle color={'success'} sx={{fontSize: 11, verticalAlign: 'middle'}}/>
                      </Typography>
                    }

                    <Box mt={.5} ml={1} mb={2}>
                        <InvitesSummary entity={entity} format={'justavatars'} canManage={canManage}/>
                    </Box>
                </Grid>
            </Box>

            <Grid item p={0}>
                <SongRequestList event_id={entity.id}/>
            </Grid>

            <Grid item mt={3} style={{textAlign: "center"}}><ButtonPill
                onClick={() => navigate(`/events/${entity.id}/request-song`)}
                variant={"contained"}
                color={"primary"}>
                Request a Song
            </ButtonPill>
            </Grid>

        </Box>
    );
}

export default EventDetails;
