import React, {useEffect} from 'react';
import {Box, Grid, LinearProgress, SvgIcon, Typography} from "@mui/material";
import FullscreenBG from "../components/FullscreenBg";
import {ButtonPill, GradientButton, SecondaryBottomMenu} from "../forms/StyledFields";
import {useNavigate} from "react-router-dom";
import {useEventsContext} from "../contexts/EventsProvider";
import EventsList from "./EventsList";
import {Add} from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import {ReactComponent as PaperPlane} from '../assets/paper-plane.svg';

const EventsDashboard: React.FC = () => {
    const navigate = useNavigate()
    const {myEvents, acceptedEvents, pendingEvents, getFilteredEvents} = useEventsContext();

    useEffect(() => {
        getFilteredEvents('invited', '');
        getFilteredEvents('accepted', '');
        getFilteredEvents('mine', '');
    }, []);

    return <React.Fragment>
        <FullscreenBG imageUrl={'/events-background.png'}/>
        <Box mt={7} style={{position: 'relative', zIndex: 1, minHeight: '100vh'}}>
            <Typography variant={"h1"} style={{textAlign: 'center'}} gutterBottom={true}>Events</Typography>
            {pendingEvents.count > 0 ?
                <Badge
                    color={'primary'}
                    onClick={() => navigate('/events/invited')}
                    overlap="circular"
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    sx={{position: 'fixed', right: 20, top: 20}}
                    badgeContent={pendingEvents.count}>

                    <SvgIcon component={PaperPlane} inheritViewBox/>

                </Badge>
                :
                <Badge
                    color={'primary'}
                    overlap="circular"
                    onClick={() => navigate('/events/invited')}
                    sx={{position: 'fixed', right: 20, top: 20}}
                    badgeContent={0}>
                    <SvgIcon component={PaperPlane} inheritViewBox/>
                </Badge>
            }


            {myEvents.count > 0 && <Box>
              <Typography mt={3} variant={'h3'} ml={2}>Your Events</Typography>
              <EventsList invite_status={'mine'} listData={myEvents}/>
            </Box>}

            {acceptedEvents.count > 0 && <Box>
              <Typography mt={3} variant={'h3'} ml={2}>Scheduled</Typography>
              <EventsList invite_status={'accepted'} listData={acceptedEvents}/>
            </Box>}


            {(!myEvents.count && !acceptedEvents.count) ?
                <Grid item style={{textAlign: 'center'}}>
                    <Typography variant={"body2"} gutterBottom={true}>You don't have any events</Typography>
                    <GradientButton
                        endIcon={<Add/>}
                        onClick={() => navigate(`/forms/events/0/add`)}>
                        Create An Event
                    </GradientButton>
                </Grid>
                :
                (myEvents.count === -1 || acceptedEvents.count === -1 || pendingEvents.count === -1) ?
                    <LinearProgress/>
                    :
                    <SecondaryBottomMenu style={{textAlign: 'center'}}>
                        <ButtonPill
                            onClick={() => navigate(`/forms/events/0/add`)}
                            variant={'contained'} style={{margin: 'auto'}}>Create an Event
                        </ButtonPill>
                    </SecondaryBottomMenu>
            }

        </Box>
    </React.Fragment>
}

export default EventsDashboard;
