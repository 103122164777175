import {ApiListResponse, EntityTypes} from "../../object-actions/types/types";
import ApiClient, {HttpResponse} from "../../config/ApiClient";
import {PagiFilters} from "../../contexts/DataProvider";

export function buildApiUrl(apiPath: string, offset: number | undefined, limit: number | undefined) {
    let apiUrl = `${apiPath}`
    if (offset && offset > 0 && limit && limit > 0) {
        if (apiUrl.indexOf('?') < 0) {
            apiUrl += `?`
        } else {
            apiUrl += `&`
        }
        apiUrl += `offset=${offset}&limit=${limit}`
    }
    return apiUrl
}

export const fetchList = async (apiPath: string, pagiFilters: PagiFilters): Promise<HttpResponse<ApiListResponse<EntityTypes>>> => {
    let apiUrl = buildApiUrl(apiPath, pagiFilters.offset, pagiFilters.limit)
    return await ApiClient.get<ApiListResponse<EntityTypes>>(apiUrl)
};

export const fetchEntity = async <T>(apiPath: string): Promise<T> => {
    let apiUrl = `/api${apiPath}`
    const httpResponse = await ApiClient.get<T>(apiUrl);
    return httpResponse.data as T
};