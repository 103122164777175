import React from 'react';
import {Box, LinearProgress} from "@mui/material";
import Logo from "../theme/Logo";
import {ButtonPill} from "../forms/StyledFields";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../allauth/auth";
import EventsDashboard from "./EventsDashboard";
import {SoloCenteredBox} from "../components/FullscreenBg";
import { AuthenticatedCompletedProfileRoute} from "../allauth/auth/routing";

interface HomeProps {
    loading?: boolean;
}

const Home: React.FC<HomeProps> = ({loading = false}) => {
    const navigate = useNavigate()
    const me = useAuth()?.data?.user

    if (me) return <AuthenticatedCompletedProfileRoute><EventsDashboard/></AuthenticatedCompletedProfileRoute>

    return (
        <React.Fragment>
            {loading && <LinearProgress/>}

            <SoloCenteredBox>
                <Box
                    sx={{textAlign: 'center', maxWidth: 400, margin: '0 auto'}}>

                    <Logo height={120} />

                    <Box style={{marginTop:50}}>
                        <ButtonPill variant={'contained'}
                                    onClick={() => navigate(`/account/sms`)}
                        >Sign up / Sign in</ButtonPill>

                        <ButtonPill fullWidth={true}
                            onClick={() => navigate(`/account/login`)}
                        >Already have an account?</ButtonPill>
                    </Box>


                </Box>

            </SoloCenteredBox>

        </React.Fragment>
    );
};

export default Home;
