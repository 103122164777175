import React, {useState} from "react";
import {Box, LinearProgress, Typography} from "@mui/material";
import {ApiListResponse, SongRequests} from "../../object-actions/types/types";
import {PagiFilters, parsePagiFiltersFromURL, useData} from "../../contexts/DataProvider";
import {fetchList} from "../../hooks/useQuery";
import SongRequestCard from "./SongRequestCard";
import TablePaginator from "../TablePaginator";
import {Link, useLocation} from "react-router-dom";

interface SongRequestListProps {
    event_id?: string | number;
}

const SongRequestList: React.FC<SongRequestListProps> = ({event_id}) => {
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const location = useLocation();
    const apiUrl = (event_id) ? `/api/song_requests/by-event/${event_id}?status=requested,accepted` : `/api/song_requests?status=requested,accepted`
    const {
        apiResponse,
        isLoading
    } = useData<ApiListResponse<SongRequests>>(apiUrl, pagination, (): any => fetchList(apiUrl, pagination));

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: limit,
            offset: offset
        }));
    }

    if (isLoading && !apiResponse?.results) return <Box p={2}><LinearProgress/></Box>

    if (apiResponse.count === 0) return null;

    return (
        <Box>
            {isLoading && <LinearProgress/>}

            {apiResponse.count > apiResponse.results.length && (
                <TablePaginator
                    total={apiResponse.count} limit={apiResponse.limit} offset={apiResponse.offset}
                    onPageChange={handlePagination}
                />
            )}
            <Box p={1}>
                {apiResponse.results.map(entity => <SongRequestCard entity={entity}
                                                                    key={`${entity._type}-${entity.id}`}/>)}
            </Box>
        </Box>
    );
}

export default SongRequestList;