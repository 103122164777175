import React from 'react';
import {Box} from '@mui/material';
import {alpha, keyframes, styled} from '@mui/system';

interface FullscreenBGProps {
    imageUrl: string;
    children?: React.ReactNode;
}

interface BackgroundImageProps extends FullscreenBGProps {
    opacity?: number;
    position?: string;
    blur?: number;
}

// Define keyframes for the opacity animation
const blurAnimation = keyframes`
    0% {
        filter: blur(3px);
      }
      50% {
        filter: blur(8px);
      }
      100% {
        filter: blur(3px);
      }
`;

export const BackgroundImage = styled(Box)<BackgroundImageProps>(
    /* @ts-ignore */
    ({theme, imageUrl, position = 'absolute', opacity = 0.3, blur = 0}: BackgroundImageProps) => ({
        position: `${position}!important`,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `
        linear-gradient(0, 
            ${alpha(theme.palette.background.default, 1)} 40%,   
            ${alpha(theme.palette.background.paper, 0)} 100%),
        url(${imageUrl})
    `,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        opacity: opacity,
        zIndex: position === 'fixed' ? -2 : 0,
        animation: `${blurAnimation} 10s infinite ease-in-out`,
        filter: blur > 0 ? `blur(${blur}px)` : 'none',
    })
);

export const SoloCenteredBox: React.FC<any> = ({children = null}) => {
    // @ts-ignore
    return <Box p={2} style={{minHeight: '100vh', display: 'flex',  alignItems:"center", justifyContent:'center'}} >{children}</Box>;
};
const FullscreenBG: React.FC<FullscreenBGProps> = ({imageUrl, children = null}) => {
    // @ts-ignore
    return <BackgroundImage imageUrl={imageUrl}>{children}</BackgroundImage>;
};

export default FullscreenBG;
