// ThemeContext.js
import React, { createContext, useMemo, useState } from 'react'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import GlobalStyles from './GlobalStyles' // Import the global styles
const ThemeContext = createContext()

const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(true)

  const theme = useMemo(
    () => {
      const plt = {
        mode: darkMode ? 'dark' : 'light',
        background: {
          default: darkMode ? '#000' : '#ffffff',
          paper: darkMode ? '#1D1D1D' : '#F5F5F5'
        },
        primary: {
          main: '#FF1374'
        },
        secondary: {
          main: '#c60f92'
        }
      }

      return createTheme({
        typography: {
          fontFamily: 'SF Pro, Jost, Arial, sans-serif',
          h0: { /* h1 */
            fontSize: '37px',
            fontWeight: 900
          },
          h1: { /* h2 */
            fontSize: '27px',
            fontWeight: 900
          },
          h2: { /* h3 */
            fontSize: '24px',
            fontWeight: 800
          },
          h3: { /* subtitle 3 */
            fontSize: '20px',
            fontWeight: 800
          },
          h4: { /* subtitle 4 */
            fontSize: '17px',
            fontWeight: 800
          },
          h5: { /* subtitle 5 */
            fontSize: '20px',
            fontWeight: 600
          },
          h6: { /* subtitle 6 */
            fontSize: '17px',
            fontWeight: 600
          },
          subtitle1: { /* subtitle 1 */
            fontSize: '20px',
            fontWeight: 500
          },
          subtitle2: { /* subtitle 2 */
            fontSize: '17px',
            fontWeight: 500
          },
          body1: { /* body1 */
            fontSize: '15px',
            fontWeight: 500
          },
          body2: { /* body2 */
            fontSize: '13px',
            fontWeight: 500
          },
          button: { /* button 1 */
            fontSize: '15px',
            fontWeight: 800
          },
          caption: { /* caption */
            fontSize: '9px',
            fontWeight: 500
          },
          overline: { /* body3 */
            fontSize: '11px',
            fontWeight: 500,
            textTransform: 'none'
          }
        },
        palette: plt,
        components: {
          MuiSnackbarContent: {
            styleOverrides: {
              root: {
                '&.MuiSnackbarContent-message': {
                  fontSize: '1rem',
                  color: '#fff', // Default text color
                },
                '&.MuiSnackbarContent-success': {
                  backgroundColor: '#d329d8', // Custom background color for success variant
                  color: '#fff', // Text color for success variant
                },
                '&.MuiSnackbarContent-error': {
                  backgroundColor: '#f44336', // Custom background color for error variant
                  color: '#fff', // Text color for error variant
                },
              },
            },
          },
        },
      })
    },
    [darkMode]
  )

  console.log('THEME UPDATE', theme)

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <GlobalStyles/>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
