import React from "react";
import {Box} from "@mui/material";
import EventInviter from "../components/EventInviter";
import FriendSelector from "../components/FriendSelector";
import {useParams} from "react-router-dom";

interface InviteHomeProps {}

const InviteHome: React.FC<InviteHomeProps> = () => {
    const params = useParams();
    const event_id = params.id ? parseInt(params.id) : 0;

    return (
        <Box mt={8} style={{minHeight: '100vh'}}>
            <Box p={2}>
                <EventInviter />
            </Box>
            <FriendSelector event_id={event_id}/>
        </Box>
    );
};

export default InviteHome;
