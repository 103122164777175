import React from 'react';
import {ButtonPill} from "../forms/StyledFields";
import {Events} from "../object-actions/types/types";
import {Link} from "react-router-dom";

interface PlaylistCtaProps {
    entity: Events;
}

const PlaylistCta: React.FC<PlaylistCtaProps> = ({entity}) => {

    return <ButtonPill
        style={{
            position: 'absolute',
            left: '50%',
            right: '50%',
            top: '15px',
            transform: 'translate(-50%, 0%)',
            textAlign: 'center',
            zIndex:11
        }}
        size={'small'}
        component={Link} to={`/events/${entity.id}/playlists`}
        variant={'contained'}>Playlists</ButtonPill>
};

export default PlaylistCta;
