import React, {useState} from "react";
import {Box, Grid, LinearProgress} from "@mui/material";
import {ApiListResponse, EventCheckins} from "../../object-actions/types/types";
import {PagiFilters, parsePagiFiltersFromURL, useData} from "../../contexts/DataProvider";
import {fetchList} from "../../hooks/useQuery";
import EventCheckinCard from "./EventCheckinCard";
import TablePaginator from "../TablePaginator";

interface EventCheckinListProps {
    event_id?: string | number;
}

const EventCheckinList: React.FC<EventCheckinListProps> = ({event_id}) => {
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const apiUrl = (event_id) ? `/api/event_checkins/by-event/${event_id}?status=entered` : `/api/event_checkins?status=entered`
    const {
        apiResponse,
        isLoading
    } = useData<ApiListResponse<EventCheckins>>(apiUrl, pagination, (): any => fetchList(apiUrl, pagination));

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: limit,
            offset: offset
        }));
    }

    if (isLoading && !apiResponse?.results) return <Box p={2}><LinearProgress/></Box>

    return (
        <Box>
            {isLoading && <LinearProgress/>}

            {apiResponse.count > apiResponse.results.length && (
                <TablePaginator
                    total={apiResponse.count} limit={apiResponse.limit} offset={apiResponse.offset}
                    onPageChange={handlePagination}
                />
            )}
            <Grid container>
                {apiResponse.results.map(entity => <EventCheckinCard entity={entity}
                                                                     key={`${entity._type}-${entity.id}`}/>)}
            </Grid>
        </Box>
    );
}

export default EventCheckinList;