import React from "react";
import {Avatar, Typography, useTheme,} from "@mui/material";
import {Likes} from "../../object-actions/types/types";
import {useAuth} from "../../allauth/auth";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {getUsername, makeAbsolute, timeAgo} from "../../utils";
import ApiClient, {HttpResponse} from "../../config/ApiClient";
import {useSnackbar} from "notistack";

interface LikeCardProps {
    entity: Likes;
}

const LikeSongRequestCard: React.FC<LikeCardProps> = ({entity}) => {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar()

    const me = useAuth()?.data?.user
    const relEntity = entity['songs']
    if (!relEntity) return <Typography variant={"body2"}>Wrong like type</Typography>;

    const likeEntity = async () => {
        const like: HttpResponse<Likes> = await ApiClient.post(`/api/likes`, {
            song: relEntity.id,
            author: me.id,
            type: "Likes"
        })

        if (like.success) {
            enqueueSnackbar(`Liked`, {variant: 'success', style: {backgroundColor: theme.palette.primary.main}})
        } else {
            enqueueSnackbar(`Error liking`, {variant: 'error'})
        }
    }

    const headerProps: Partial<CardHeaderProps> = {};

    const username = getUsername(entity['author'])

    if (typeof relEntity.img === 'string') {
        headerProps.avatar =
            <Avatar src={makeAbsolute(relEntity.img)}
                    alt={username}/>
    } else {
        headerProps.avatar =
            <Avatar src={relEntity['str']} alt={relEntity['str']}/>
    }

    headerProps.title = <Typography variant={"body2"}>
        {username} liked {" "}
        <b>"{relEntity.str}"</b>

        <Typography color={'grey'} ml={1} variant='caption'>{timeAgo(entity.created_at)}</Typography>
    </Typography>
//    headerProps.action = <FavoriteBorder onClick={() => likeEntity()}/>

    return <CardHeader style={{borderBottom: `1px solid ${theme.palette.divider}`, width: '100%'}}
                       key={`entity-${entity.id}-${entity._type}`}
                       {...headerProps} />
}

export default LikeSongRequestCard;
