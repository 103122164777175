import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Notifications from '@mui/icons-material/Notifications';
import Search from '@mui/icons-material/Search';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import {useAuth} from "../allauth/auth";
import {useNavigate} from "react-router-dom";
import {makeAbsolute} from "../utils";

export default function BottomMenu() {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()
    const me = useAuth()?.data?.user

    if (!me.profile_picture || !me.display) return null; // still onboarding

    return <Paper sx={{
        position: 'fixed',
        bottom: 0, left: 0, right: 0, zIndex: 99
    }} elevation={3}>
        <BottomNavigation
            sx={{backgroundColor: 'background.default'}}
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                if (newValue === 'account') {
                    navigate(`/my-profile`)
                } else if (newValue === 'calendar') {
                    navigate(`/events`)
                } else if (newValue === 'search') {
                    navigate(`/friendships`)
                } else if (newValue === 'notifications') {
                    navigate(`/activity`)
                }
            }}
        >
            <BottomNavigationAction value={'calendar'} icon={<CalendarToday/>}/>
            <BottomNavigationAction value={'search'} icon={<Search/>}/>
            <BottomNavigationAction value={'notifications'} icon={<Notifications/>}/>
            {me && <BottomNavigationAction value={'account'}
                                             icon={<Avatar sx={{width: 30, height: 30}}
                                                           alt={me.display}
                                                           src={me.profile_picture ? makeAbsolute(me.profile_picture) : me.img}
                                             />}
            />}
        </BottomNavigation>
    </Paper>
}