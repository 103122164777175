import React, {createContext, ReactNode, useContext, useState} from 'react';
import {ApiListResponse, Events} from "../object-actions/types/types";
import ApiClient from "../config/ApiClient";

interface EventsContextType {
    myEvents: ApiListResponse<Events>;
    acceptedEvents: ApiListResponse<Events>;
    pendingEvents: ApiListResponse<Events>;

    getFilteredEvents: (invite_status: string, search: string | undefined) => void;

    setMyEvents: (paginated: ApiListResponse<Events>) => void;
    setPendingEvents: (paginated: ApiListResponse<Events>) => void;
    setAcceptedEvents: (paginated: ApiListResponse<Events>) => void;
}

const EventsContext = createContext<EventsContextType | undefined>(undefined);

interface EventsProviderProps {
    children: ReactNode;
}

const defaultApiResponse: ApiListResponse<Events> = {
    count: -1,
    limit: 20,
    offset: 0,
    results: [],
    error: null,
    meta: {}
}

export const EventsProvider: React.FC<EventsProviderProps> = ({children}) => {
    const [myEvents, setMyEvents] = useState<ApiListResponse<Events>>(defaultApiResponse);
    const [pendingEvents, setPendingEvents] = useState<ApiListResponse<Events>>(defaultApiResponse);
    const [acceptedEvents, setAcceptedEvents] = useState<ApiListResponse<Events>>(defaultApiResponse);

    const getFilteredEvents = async (invite_status: string, search: string | undefined = '') => {

        let apiUrl = `/api/events/${invite_status}`
        if (search) {
            apiUrl += `?${search}`
        }
        const response = await ApiClient.get(apiUrl);
        if (invite_status === 'invited') {
            setPendingEvents(response.data as ApiListResponse<Events>)
        } else if (invite_status === 'mine') {
            setMyEvents(response.data as ApiListResponse<Events>)
        } else if (invite_status === 'accepted') {
            setAcceptedEvents(response.data as ApiListResponse<Events>)
        } else {
            console.warn('UNHANDLED EVENTS TYPE: ' + invite_status)
        }
    };

    return (
        <EventsContext.Provider
            value={{
                myEvents,
                pendingEvents,
                acceptedEvents,

                getFilteredEvents,

                setMyEvents,
                setAcceptedEvents,
                setPendingEvents,
            }}
        >
            {children}
        </EventsContext.Provider>
    );
};

// Custom hook for using the context
export const useEventsContext = (): EventsContextType => {
    const context = useContext(EventsContext);
    if (!context) {
        throw new Error('useEventsContext must be used within a EventsProvider');
    }
    return context;
};
