import React, {useState} from "react";
import {Box, Grid, LinearProgress, Typography} from "@mui/material";
import {
    ApiListResponse,
    EntityTypes,
    Events,
    Friendships,
    Invites,
    NAVITEMS,
    Playlists,
    SongRequests,
    Songs,
    Users
} from "../object-actions/types/types";
import TablePaginator from "../components/TablePaginator";
import {useLocation, useNavigate} from "react-router-dom";
import {Add} from "@mui/icons-material";
import Friendship from "../components/Friendship";
import EntityCard from "../object-actions/EntityCard";
import {EventShortView} from "./EventDetails";
import Playlist from "../components/Playlist";
import Song from "../components/Song";
import UserCard from "../components/UserCard";
import EventsList from "./EventsList";
import Invite from "../components/Invite";
import SongRequestCard from "../components/activities/SongRequestCard"
import {getModelName} from "../utils";
import {GradientButton} from "../forms/StyledFields";
import {PagiFilters, parsePagiFiltersFromURL, useData} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";
import {SoloCenteredBox} from "../components/FullscreenBg";

interface EntityListProps {
    model?: string;
    author?: number | string;
    search_filters?: string;
}

const EntityList: React.FC<EntityListProps> = ({model, author = false, search_filters = ''}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());

    const hasUrl = NAVITEMS.find((nav) => {
        if (!model) {
            return location.pathname.indexOf(nav.screen) === 0;
        } else {
            return model === nav.type;
        }
    }) ?? NAVITEMS.find(nav => nav.type === 'Events')!;

    const title = model ? getModelName(model) : hasUrl.name;

    let apiUrl = ``;
    if (author) {
        apiUrl += `/api/users/${author}/${hasUrl.type.toLowerCase()}`;
    } else {
        apiUrl += hasUrl.api;
    }
    const params = new URLSearchParams(location.search);
    apiUrl += `?${params.toString()}`;

    if (search_filters) {
        apiUrl += `&${search_filters}`
    }

    const {
        apiResponse,
        error,
        isLoading
    } = useData<ApiListResponse<EntityTypes>>(apiUrl, pagination, (): any => fetchList(apiUrl, pagination));

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => ({
            ...prevPagination,
            limit: limit,
            offset: offset
        }));
    }

    if (isLoading && !apiResponse?.results) return <Box p={2}><LinearProgress/></Box>

    if (error)
        return <SoloCenteredBox><Typography variant={'body2'} color={"error"}>{error}</Typography> </SoloCenteredBox>
    if (title === "Events") {
        return (
            <EventsList
                invite_status={"mine"}
                listData={apiResponse}
            />
        );
    }

    if (apiResponse?.results.length === 0) {
        return (
            <Grid sx={{padding: 2, textAlign: "center"}} mt={1}>
                {title === "Invites" ? (
                    <React.Fragment>
                        <Typography variant={"body2"} gutterBottom={true}>
                            You don't have any confirmed events
                        </Typography>

                        <GradientButton
                            endIcon={<Add/>}
                            onClick={() => navigate(`/forms/events/0/add`)}
                        >
                            Create An Event
                        </GradientButton>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography variant={"body2"}>No {title}</Typography>
                    </React.Fragment>
                )}
            </Grid>
        );
    }

    return (
        <Box sx={{padding: 2}} mt={1}>
            <React.Fragment>
                {apiResponse.count > apiResponse?.results.length && (
                    <Grid
                        container
                        justifyContent={"space-between"}
                        alignContent={"center"}
                        alignItems={"center"}
                    >
                        <Grid item>{hasUrl.name}</Grid>
                        <TablePaginator
                            total={apiResponse.count} limit={apiResponse.limit} offset={apiResponse.offset}
                            onPageChange={handlePagination}
                        />
                    </Grid>
                )}
                <Grid container spacing={2}>
                    {apiResponse?.results.map((obj, i) => (
                        <Grid xs={obj._type === "Playlists" || obj._type === "Songs" ? 6 : 12} item
                              key={`entitycard-${i}`}>
                            {obj._type === "Users" ? (
                                <UserCard entity={obj as Users}/>
                            ) : obj._type === "Friendships" ? (
                                <Friendship entity={obj as Friendships}/>
                            ) : obj._type === "Invites" ? (
                                <Invite entity={obj as Invites}/>
                            ) : obj._type === "Events" ? (
                                <EventShortView entity={obj as Events} viewMode={'short'}/>
                            ) : obj._type === "Playlists" ? (
                                <Playlist entity={obj as Playlists}/>
                            ) : obj._type === "Songs" ? (
                                <Song entity={obj as Songs}/>
                            ) : obj._type === "SongRequests" ? (
                                <SongRequestCard entity={obj as SongRequests}/>
                            ) : (
                                <EntityCard entity={obj}/>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </React.Fragment>
        </Box>
    );
};

export default EntityList;
