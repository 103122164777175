import React, {useEffect, useRef, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {Invites, RelEntity} from "../object-actions/types/types";
import ApiClient from "../config/ApiClient";
import {ButtonPill} from "../forms/StyledFields";
import {Check} from "@mui/icons-material";
import FullscreenBG, {BackgroundImage} from "./FullscreenBg";
import FullScreenModal from "./FullScreenModal";
import {getUsername, makeAbsolute} from "../utils";
import {parsePagiFiltersFromURL, useDataContext} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface InviteProps {
    entity: Invites;
}

const InviteRsvp: React.FC<InviteProps> = ({entity}) => {
    const [status, setStatus] = useState<string>(entity.status);
    const [showRsvp, setRsvp] = useState<boolean>(false);
    const refTimeout = useRef();
    const {fetchData} = useDataContext();

    const handleStatusChange = async (value: string) => {
        const response = await ApiClient.patch(`/api/invites/${entity.id}`, {status: value});
        if (response.success && response.data) {
            console.log(response.data);
            setStatus(value);
            if (value === "accepted") {
                setRsvp(true);
            }

            const apiUrl = (entity.event?.id && window.location.pathname.indexOf('/events/') === 0) ? `/api/invites/by-event/${entity.event?.id}?status=accepted,invited` : `/api/invites?status=accepted,invited`
            const pagination = parsePagiFiltersFromURL()
            fetchData(apiUrl, (): any => fetchList(apiUrl, pagination), true)
        }
        if (response.error) {
            alert(response.error);
        }
    };

    useEffect(() => {
        if (showRsvp) {
            setTimeout(() => {
                setRsvp(false);
            }, 4000);
        }

        return () => {
            clearTimeout(refTimeout.current);
        };
    }, [showRsvp]);

    return (
        <React.Fragment>
            <FullScreenModal
                open={showRsvp}
                onClose={() => setRsvp(false)}
                title="Invite List"
            >
                {entity.event.id ? (
                    <InviteAcceptedModal entity={entity}/>
                ) : null}
            </FullScreenModal>
            <Grid container spacing={1} justifyContent={"space-between"}>

                <Grid item xs={6}>
                    <ButtonPill
                        onClick={() => handleStatusChange("declined")}
                        variant={"contained"}
                        startIcon={status === "declined" ? <CheckCircleIcon/> : undefined}
                        fullWidth={true}
                        color="inherit"
                    >
                        Can't make it
                    </ButtonPill>
                </Grid>
                <Grid item xs={6}>
                    <ButtonPill
                        onClick={() => handleStatusChange("accepted")}
                        variant={"contained"}
                        fullWidth={true}
                        startIcon={status === "accepted" ? <CheckCircleIcon/> : undefined}
                        color={"primary"}
                    >
                        I'll be there
                    </ButtonPill>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};


const InviteAcceptedModal: React.FC<InviteProps> = ({entity}) => {

    const event = entity.event as RelEntity;

    return (
        <React.Fragment>

              {/* @ts-ignore */}
            <BackgroundImage imageUrl={makeAbsolute(event.img)}
                             position={'absolute'}
                             opacity={1} blur={8}/>

            <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}
                  style={{height: '100%', textAlign: 'center', position: 'relative'}} gap={2} >
                <Typography variant={"h2"}>You're going to </Typography>
                {event.img && (
                    <img
                        alt={"event flyer"}
                        src={makeAbsolute(event.img)}
                        style={{maxHeight: 200, margin: "auto"}}
                    />
                )}
                <Typography variant={"h2"} style={{margin:0}}>{event.str}</Typography>
                <Typography variant={"h4"} style={{margin:0}}>
                    {getUsername(entity.author)}
                </Typography>
                <p style={{textAlign:'center'}}>
                 <Check style={{textAlign:'center', fontSize:50, fontWeight:900}}/>
                </p>
            </Grid>
        </React.Fragment>
    );
}

export default InviteRsvp;
